<template>
  <div class="GlobalFormDemo">
    <GlobalForm :init-data="initData" :needBtnLoading="true" :form-rules="formRules" :form-item-list="formItemList" :inline="true" round
      @handleChange="handleChange" @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item slot="companyId" class="el_from" :label-width="'80px'" label="商家ID">
        <el-input clearable v-model="companyId"  placeholder="请输入商家ID"></el-input>
      </el-form-item>
      <el-form-item slot="companyName" class="el_from" :label-width="'80px'" label="商家名称">
        <el-input v-model="companyName" placeholder="请输入商家名称"></el-input>
      </el-form-item>
      <el-form-item class="el_from" :label-width="'80px'" label="报价矩阵" slot="matrixSelect">
        <el-select clearable filterable v-model="matrixId" placeholder="请选择矩阵名称查询">
          <el-option v-for="item in matrixSelectList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" :label-width="'80px'" label="排序方式" slot="orderType" style="margin: 0">
        <el-select v-model="orderType" placeholder="请选择排序方式">
          <el-option v-for="item in sortOption" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" :label-width="'100px'" label="累计订单量" slot="orderNum" style="margin: 0">
        <el-input style="width: 120px" v-model="startRecycles" min="0" type="number" clearable />
      </el-form-item>
      <el-form-item class="el_from" :label-width="'0px'" slot="orderNum"
        style="margin: 0;position: relative;margin-left: 20px;">
        <i style="font-size: 22px;position: absolute;left: -17px;top: 3px">~</i>
        <el-input style="width: 120px" v-model="endRecycles" type="number" clearable />
      </el-form-item>
      <div slot="AddSlot">
        <el-button size="small" type="danger" @click="openTimeLimit"
          >限时抢单设置</el-button
        >
        <el-button size="small" type="danger" @click="batchForbidden">批量禁用商户
        </el-button>
        <el-button size="small" type="warning" @click="detectionSet">系统检测设置
        </el-button>
        <el-button size="small" type="success" @click="openAddPrice">平台加价设置
        </el-button>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="addOneMenu">添加商家
        </el-button>
      </div>
    </GlobalForm>

    <el-dialog class="address_dialog" title="收货地址设置" :visible.sync="setAddressShow" :close-on-click-modal="false"
      width="500px" @closed="setAddressClosed">
      <div style="margin-bottom: 20px">
        <span style="color: #ff687b; font-size: 16px">说明：该收货地址用于设置收货方为平台的商户，员工在线下发货查看地址与线上发货时使用。</span>
      </div>
      <el-form label-width="97px" :model="addressForm" :rules="addressFormRules" ref="addressFormRules">
        <el-form-item label="收货人姓名:" prop="contactName">
          <el-input size="small" clearable v-model="addressForm.contactName" placeholder="请输入收货人姓名"></el-input>
        </el-form-item>
        <el-form-item label="收货人电话:" prop="contactPhone">
          <el-input size="small" clearable v-model.number="addressForm.contactPhone" placeholder="请输入收货人电话"></el-input>
        </el-form-item>
        <el-form-item label="详细地址:" prop="merchantAddress">
          <el-input size="small" clearable maxlength="30" v-model="addressForm.merchantAddress"
            placeholder="请输入详细地址，最多30个字"></el-input>
        </el-form-item>
        <el-form-item label="门牌号:">
          <el-input size="small" maxlength="10" clearable v-model="addressForm.houseAddress"
            placeholder="请输入门牌号，最多10个字"></el-input>
        </el-form-item>
        <el-form-item label="动态口令:" prop="command">
          <el-input size="small" clearable v-model.trim="addressForm.command" placeholder="请输入谷歌动态口令"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="setAddressShow = false">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="addressSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 原加价设置弹窗 -->
    <!--    <el-dialog-->
    <!--        title="平台加价设置"-->
    <!--        :visible.sync="addPriceShow"-->
    <!--        :close-on-click-modal="false"-->
    <!--        width="800px"-->
    <!--    >-->
    <!--      <div>-->
    <!--        <span-->
    <!--            style="color: #ff687b;font-size: 14px;">1.开启后仅零售通渠道订单支持加价，平台发起报价按照提交时商家最高成交价金额计算提交的报价；</span>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <span style="color: #ff687b;font-size: 14px;">2.用于平台加价的回收商需要通过数据库配置。</span>-->
    <!--      </div>-->
    <!--      <div class="flex mt-20" style="color:#333;font-size:16px">-->
    <!--        <div style="margin-right:10px;">开启平台加价功能：</div>-->
    <!--        <el-switch-->
    <!--            v-model="isOpen"-->
    <!--            active-text="开"-->
    <!--            inactive-text="关"-->
    <!--            active-value="01"-->
    <!--            inactive-value="00"-->
    <!--        >-->
    <!--        </el-switch>-->
    <!--      </div>-->
    <!--      <div class="mt-20" style="color:#333;font-size:16px">-->
    <!--        <span>平台加价比例设置</span>-->
    <!--        <span-->
    <!--            style="color: #ff687b;font-size: 14px;margin-left:30px">计算公式：平台报价=（1+加价比例）×最高商家成交价</span>-->
    <!--      </div>-->
    <!--      <GlobalTable-->
    <!--          class="mt-20"-->
    <!--          style='padding-bottom:20px;'-->
    <!--          ref="GlobalTable"-->
    <!--          :columns="regionColumns"-->
    <!--          :data="regionList"-->
    <!--          :maxHeight="500"-->
    <!--          :isPagination="false"-->
    <!--      >-->
    <!--        <el-table-column label="最高成交价区间" width="135px" slot="rank" align="center">-->
    <!--          <template slot-scope="{ row }">-->
    <!--            <span>{{ row.lower }}＜区间≤{{ row.upper }}</span>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column label="加价比例" slot="markUpRate" align="center">-->
    <!--          <template slot-scope="{ row }">-->
    <!--            <span>{{ row.markUpRate }}%</span>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column label="加价上限" slot="markUpLimit" align="center">-->
    <!--          <template slot-scope="{ row }">-->
    <!--            <span>￥{{ row.markUpLimit }}</span>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column label="操作" slot="operation" align="center">-->
    <!--          <template slot-scope="{ row }">-->
    <!--            <el-button type="primary" size="mini" @click="editConfig(row)">编辑</el-button>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--      </GlobalTable>-->
    <!--      <span slot="footer" class="dialog-footer">-->
    <!--        <el-button @click.native="addPriceShow = false">取消</el-button>-->
    <!--        <el-button type="primary" @click.native="submitAddPrice()"-->
    <!--        >确定</el-button>-->
    <!--      </span>-->
    <!--    </el-dialog>-->
    <el-dialog title='编辑加价规则' :visible.sync="editPriceShow" :close-on-click-modal="false" width="400px"
      @closed="editPriceClosed">
      <el-form :model="editPriceForm" :rules="editPricerules" ref="editPriceRulesForm" label-width="83px">
        <el-form-item label="最高报价区间:" label-width="100px">
          <el-col :span="11">
            <el-form-item>
              <el-input size="small" v-model.trim="editPriceForm.lower" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col style="text-align: center" :span="2">~</el-col>
          <el-col :span="11">
            <el-form-item>
              <el-input size="small" v-model.trim="editPriceForm.upper" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="加价比例:" prop="markUpRate">
          <el-input size="small" placeholder="输入加价比例" v-model.trim="editPriceForm.markUpRate"
            @input="limitInput($event, 'markUpRate')" @blur="inputBlur('markUpRate')">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="加价上限:" prop="markUpLimit">
          <el-input size="small" placeholder="输入加价上限" maxlength="5" v-model.trim="editPriceForm.markUpLimit"
            @input="(val) => (editPriceForm.markUpLimit = val.replace(/\D/g, '').replace(/^0{1,}/g, ''))">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="editPriceShow = false">取消</el-button>
        <el-button type="primary" @click.native="submitEditPrice()">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title='操作提示' :visible.sync="commandShow" :close-on-click-modal="false" width="400px"
      @closed="commandClosed">
      <div class="tip-sure">是否确认修改平台加价配置？</div>
      <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          @input="(val) => (command = val.replace(/[^\d]/g, ''))"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandShow = false">取消</el-button>
        <el-button type="primary" :loading="commandBtnLoading" @click.native="commandSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 系统检测设置 -->
    <el-dialog title='系统检测设置' :visible.sync="csetionShow" :close-on-click-modal="false" width="500px"
      @closed="commandClosed">
      <div style="color: rgb(255, 128, 128);">说明：用于新估价方案，预估价高于设置值时，优先使用系统检测；仅手机正常开机需进行系统检测</div>
      <div style="display: flex;align-items: center;margin: 20px 0;">
        <p>按原始预估价设置：</p>
        <div class="futureprices">
          预估价≥ <div style="width: 100px;"> <el-input maxlength="6" oninput="value=value.replace(/[^\d]/g,'')" type="text"
              v-model="redyPrice"> </el-input></div> 元优先使用系统检测
        </div>
      </div>
      <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          @input="(val) => (command = val.replace(/[^\d]/g, ''))"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="csetionShow = false">取消</el-button>
        <el-button type="primary" :loading="commandBtnLoading" @click.native="csetionSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="导入禁用商户" :visible.sync="importVisible" :close-on-click-modal="false"
      class="import-business-dialog" width="540px" @close="exportinDialogClosed">
      <div class="cance_tip">
        <div>
          说明：按照数据模板填写需禁用商户信息，导入后确定禁用即可，禁用原因由为固定原因，此处无需填写。
        </div>
      </div>
      <el-button type="success" icon="el-icon-download" size="small" @click="download">下载数据模板</el-button>
      <!-- 上传 -->
      <el-form ref="importForm" :model="importForm" :rules="importRules" label-width="80px" style="margin-top: 20px;">
        <el-form-item label="表格上传" prop="fileName" class="fileName">
          <el-input v-model="importForm.fileName" @clear="fileClear" :disabled="true" size="medium" clearable>
          </el-input>
          <div style="float: right; position: relative">
            <el-button size="medium" type="warning" icon="el-icon-upload2">上传表格</el-button>
            <input accept=".xls, .xlsx" ref="fileUpload" @change="fileChange" type="file" style="
                  opacity: 0;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  cursor: pointer;
                " />
          </div>
          <div style="color: #ff687b">
            仅支持.xls及.xlsx格式,单次上传大小不超过5M
          </div>
        </el-form-item>
        <el-form-item label="动态口令" required>
          <el-input v-model="command" placeholder="请输入谷歌动态口令"
          @input="(val) => (command = val.replace(/[^\d]/g, ''))"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click.native="exportinDialogClosed">取消</el-button>
        <el-button type="primary" :loading="importLoading" @click.native="importSuccess">确定禁用</el-button>
      </span>
    </el-dialog>
     <!-- 限时抢单弹框 -->
     <el-dialog
      title="限时抢单设置"
      :visible.sync="timeLimitOrderShow"
      :close-on-click-modal="false"
      width="900px"
      @closed="timeLimitOrderShow = false"
    >
      <div class="addparto" style="margin-bottom: 15px">
        说明：开启设置限时抢单后，在对应时间段下单的订单，回收商需在抢单时长内完成抢单报价，超出不可报价
      </div>
      <div
        class="fund-title"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px
        "
      >
        <span style="color: #333; font-weight: 700">限时抢单规则</span>
        <div>
          <el-button
          size="mini"
          type="warning"
          @click="seeALLmerchant"
          >等待报价图片配置</el-button
        >
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="addRule('add')"
          >添加规则</el-button
        >
        </div>
      </div>
      <el-table
        border
        stripe
        size="small"
        :maxHeight="500"
        :data="timeLimitOrderList"
        :isPagination="false"
      >
        <!-- 序号 -->
        <el-table-column label="序号" width="50" prop="index" align="center">
          <template v-slot="{ row }">
            <div>{{ row.index }}</div>
          </template>
        </el-table-column>
        <!-- 时间段 -->
        <el-table-column label="时间段" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.limitExclusiveStart }}-{{ row.limitExclusiveEnd }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="限时抢单时长"
          prop="limitExclusiveLength"
          align="center"
        >
          <template slot-scope="{ row }">
            <div>{{ row.limitExclusiveLength }} <span>分钟</span></div>
          </template>
        </el-table-column>
        <el-table-column label="开启限时抢单" prop="isEnable" align="center">
          <template slot-scope="{ row }">
            <div class="service_charge">
              <el-switch
                v-model="row.isEnable"
                active-text="开"
                inactive-text="关"
                inactive-color="#b6b9d0"
                @change="(e) => timeLimitOpen(e, row,'rab')"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="报价等待时长"
          prop="quoteWaitLength"
          align="center"
        >
          <template slot-scope="{ row }">
            <div>{{ row.quoteWaitLength }} <span>分钟</span></div>
          </template>
        </el-table-column>
        <el-table-column label="开启报价等待" prop="isQuoteWait" align="center">
          <template slot-scope="{ row }">
            <div class="service_charge">
              <el-switch
                v-model="row.isQuoteWait"
                active-text="开"
                inactive-text="关"
                inactive-color="#b6b9d0"
                @change="(e) => timeLimitOpen(e, row,'wait')"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-slot="{ row }">
          <el-button type="text" @click="addRule('edit', row)">编辑</el-button>
          <span class="delet-btn" @click="deleteCurrentTime(row)">删除</span>
        </el-table-column>
      </el-table>
      <div style="height: 40px; width: 100%"></div>
    </el-dialog>
    <!-- 限时抢单规则设置弹框 -->
    <el-dialog
      :title="ruleTitle"
      :visible.sync="ruleAddShow"
      :close-on-click-modal="false"
      width="600px"
      @closed="ruleAddShowClose"
    >
      <!-- 时间段 -->
      <div class="flex limit_time mt-20">
        <div style="margin-right: 42px">时间段：</div>
        <el-time-select
          size="small"
          :clearable="false"
          placeholder="起始时间"
          v-model="saveRuleParam.limitExclusiveStart"
          :picker-options="{
            start: '00:00',
            step: '01:00',
            end: '23:00',
          }"
        >
        </el-time-select>
        <div style="margin: 0 10px">~</div>
        <el-time-select
          size="small"
          :clearable="false"
          placeholder="结束时间"
          v-model="saveRuleParam.limitExclusiveEnd"
          :picker-options="{
            start: '01:00',
            step: '01:00',
            end: '24:00',
            minTime: saveRuleParam.limitExclusiveStart,
          }"
        >
        </el-time-select>
      </div>
      <!-- 限时抢单时长 -->
      <div class="flex mt-20">
        <span>限时抢单时长：</span>
        <el-input
          style="width: 366px !important"
          placeholder="请输入大于10的整数"
          v-model="saveRuleParam.limitExclusiveLength"
          clearable
          @input="timeLimitChange"
          @blur="limitTimeBlur"
        >
        </el-input>
        <span style="margin-left: 6px">分钟</span>
      </div>
       <!-- 等待报价时长 -->
       <div class="flex mt-20">
        <span>等待报价时长：</span>
        <el-input
          style="width: 366px !important"
          placeholder="仅支持正整数，输入数字需小于限时抢单时长"
          v-model="saveRuleParam.quoteWaitLength"
          clearable
          @input="waiteLimitChange"
        >
        </el-input>
        <span style="margin-left: 6px">分钟</span>
      </div>
      <div style="margin: 30px 0; text-align: right">
        <span slot="footer">
          <el-button @click.native="ruleAddShow = false">取消</el-button>
          <el-button type="primary" @click.native="submitTimeRule()"
            >确定</el-button
          >
        </span>
      </div>
    </el-dialog>
    <!-- 开启限时抢单弹框 -->
    <el-dialog
      :title="timeLimitOpenProps.title"
      :visible.sync="timeLimitOpenProps.show"
      :close-on-click-modal="false"
      width="500px"
      @closed="timeLimitSwithClose"
    >
      <div style="color: #ff8080; margin-bottom: 20px">
        <span v-if="limitRow.type=='rab'"> 说明：{{
         limitRow.isEnable ? "启用" : "关闭"
        }}后，该时间段{{limitRow.isEnable?'将':'不再'}}限制抢单时长</span>
         <span v-else> 说明：{{
          limitRow.isQuoteWait ? "启用后，" : "关闭后，"
        }}{{limitRow.isQuoteWait?'店员进入确认交易页面按照等待报价时长开始倒计时，倒计时结束后显示报价':'店员进入确认交易页面仍按照限时抢单开始倒计时'  }}</span>
      </div>
      <div class="tip-sure">
        <span v-if="limitRow.type=='rab'">是否确认{{
          limitRow.isEnable  ? "开启" : "关闭"
        }}限时抢单？</span>
        <span v-else>是否确认{{
          limitRow.isQuoteWait  ? "开启" : "关闭"
        }}报价等待？</span>
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="timeLimitCommand"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="timeLimitSwithClose">取消</el-button>
        <el-button type="primary" @click.native="timeLimitCommandSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 删除限时抢单弹框 -->
    <el-dialog
      title="删除提示"
      :visible.sync="timeDeletShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="timeDeletClosed"
    >
      <div class="tip-sure">是否确认删除该时间段抢单设置？</div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="timeDeletCommand"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="timeDeletShow = false">取消</el-button>
        <el-button type="primary" @click.native="timeDeletSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
 <!--弹框-->
 <el-dialog
      class="set-img"
      title="设置海报"
      :visible.sync="imageDialogVisible"
      :close-on-click-modal="false"
      width="550px"
      @closed="imageHandleClose"
    >
      <div>
        <div style="margin-bottom: 10px; font-size: 14px; color: #333333">
         <span style="color: red">*</span>等待图片
        </div>
        <div class="demo-image__preview">
          <ElImgUploadGroup
                place-txt="(提示：图片仅支持上传jpg/png/gif格式，建议尺寸：450-270px,最多3张)"
                :length="3"
                :exist-img-list="posterImage"
                @handleChange="sampleHandleChange($event)"
              />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="imageDialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click.native="imageHandleSure"
          >确定</el-button
        >
      </span>
    </el-dialog>

  </div>
</template>

<script>
import _api from "@/utils/request";
import ElImgUploadGroup from "../../components/global/components/elements/upload-group-element.vue";
let checkNum = (rule, value, callback) => {
  if (value == 0 || value > 100) {
    callback(new Error('加价比例必须大于0小于等于100'))
  } else {
    callback()
  }
}
export default {
  name: "Form",
  components: {
    ElImgUploadGroup
  },
  data() {
    const { matrixName } = this.$route.query;
    return {
      posterImage:[],
      btnLoading:false,
      imageDialogVisible: false,
        // 限时抢单**********
        timeLimitOrderShow: false, //弹框
      timeLimitOrderList: [], //表格数据
      ruleTitle: "", //规则弹框标题
      ruleAddShow: false, //规则弹框
      saveRuleParam: {}, //添加/编辑规则传参
      limitCommand: "", //编辑动态口令
      timeLimitOpenProps: {
        //开启限时抢单
        show: false,
        title: "",
      },
      merchantId: "",
      timeLimitCommand: "", //开启动态口令
      limitRow: {}, //
      timeDeletShow: false, //删除弹框
      timeDeletCommand: "", //删除动态口令
      weiprices: '',
      csetionShow: false,//系统检测设置
      // 矩阵下拉
      matrixSelectList: [],
      initData: null,
      companyName: "",
      companyId:"",
      matrixId: matrixName,
      contact: "",
      phone: "",
      orderType: "01",
      // 最大订单数
      startRecycles: "",
      endRecycles: "",
      formItemList: [
        {slotName:'companyId'},
        { slotName: "companyName" },
        {
          key: "contact",
          type: "input",
          labelName: "联系人",
          placeholder: "请输入联系人",
        },
        {
          key: "phone",
          type: "input",
          labelName: "联系电话",
          placeholder: "请输入联系电话",
        },
        {
          labelName: "省市查询",
          key: 'provinceId',
          //需要被清除的key，相当于子
          childrenKey: "cityId",
          type: "selectFilterable",
          placeholder: "请选择",
          lableWidth: '80px',
          clear: true,
          option: [],
          clearFun: () => false
        },
        {
          labelName: "",
          key: 'cityId',
          fatherKey: "provinceId",
          type: "selectFilterable",
          placeholder: "请先选择省",
          lableWidth: '0',
          clear: true,
          option: [],
          disabled: true,
          clearFun: () => false
        },
        {
          key: "channel",
          type: "select",
          lableWidth: "80px",
          labelName: "注册渠道",
          placeholder: "请选择注册渠道",
          option: [
            { label: "全部", value: "" },
            { label: "后台", value: "01" },
            { label: "前端", value: "02" },
          ],
        },
        {
          key: "isSymbolUsed",
          type: "select",
          lableWidth: "120px",
          labelName: "是否正式启用",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: true,
              label: "是",
            },
            {
              value: false,
              label: "否",
            },
          ],
        },
        {
          key: "isOpenPc",
          type: "select",
          lableWidth: "140px",
          labelName: "是否启用门店后台",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: true,
              label: "是",
            },
            {
              value: false,
              label: "否",
            },
          ],
        },
        {
          slotName: "matrixSelect"
        },
        { slotName: "orderType" },
        { slotName: "orderNum" },
        { slot: "AddSlot" },
      ],
      sortOption: [
        {
          value: "01",
          label: "按添加时间",
        },
        {
          value: "02",
          label: "按门店数量",
        },
      ],
      formRules: {
        staffName: [],
      },
      setAddressShow: false,
      butLoading: false,
      addressForm: {
        contactName: "",
        contactPhone: "",
        merchantAddress: "",
        houseAddress: "",
        command: "",
      },
      addressFormRules: {
        contactName: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入收货人电话", trigger: "blur" },
        ],
        merchantAddress: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
          { max: 30, message: "最多30个字", trigger: "blur" },
        ],
        command: [
          { required: true, message: "请输入谷歌动态口令", trigger: "blur" },
        ],
      },
      addPriceShow: false,
      regionList: [],
      isOpen: false,
      regionColumns: [
        { slotName: "rank" },
        { slotName: "markUpRate" },
        { slotName: "markUpLimit" },
        { slotName: "operation" },
      ],
      commandShow: false,
      redyPrice:"",
      command: "",
      commandBtnLoading: false,
      editPriceShow: false,
      rowId: "",
      editPricerules: {
        markUpRate: [
          { required: true, message: "请输入加价比例", trigger: "blur" },
          { type: "number", validator: checkNum, trigger: "blur" },
        ],
        markUpLimit: [
          { required: true, message: "输入加价上限金额", trigger: "blur" }
        ],
      },
      editPriceForm: {
        lower: "",
        upper: "",
        markUpRate: "",
        markUpLimit: "",
      },
      // 导入禁用商户
      importForm: {
        fileName: '',
        file: null
      },
      importRules: {
        fileName: [
          {
            required: true,
            message: "仅支持.xls及.xlsx格式,单次上传大小不超过5M",
            trigger: "blur",
          },
        ],
      },
      importVisible: false,
      importLoading: false,
    };
  },
  watch:{
    companyId:{
      handler(onl,newew){
          console.log(onl);
          this.companyId=onl.replace(/\D/g,'')
      }
    }
  },
  created() {
    if (this.$route.query.companyName) {
      this.companyName = this.$route.query.companyName;
    }
    this.getSelectMatrixList();
    this.handleConfirm();
    this.getProvince();
    
  },
  methods: {
     // 设置示例图弹框关闭
     imageHandleClose() {
      this.posterImage = [];
      this.imageDialogVisible = false;
    },
    // 圖片更換
    sampleHandleChange(e) {
      this.posterImage = e;
    },
    // 设置图片
    seeALLmerchant() {
      _api.getwaitimages().then(res=>{
        this.imageDialogVisible = true;
        this.posterImage = res.data.imageList||[];
      })
    },
    // 图片保存确定
    imageHandleSure() {
      if (!this.posterImage.length===0) {
        this.$message.error("图片不能为空");
        return;
      }
      this.btnLoading = true;
        _api
          .savewaitimages({ imageList:this.posterImage })
          .then((res) => {
            if (res.code === 1) {
              this.$message.success(res.msg || "操作成功");
              this.imageDialogVisible = false;
            }
            this.btnLoading = false;
          })
          .catch(() => {
            this.btnLoading = false;
          });
    },
     // 限时抢单时长规则
     timeLimitChange(v) {
      this.saveRuleParam.limitExclusiveLength = v;
      if (parseInt(v) < 0) {
        return (this.saveRuleParam.limitExclusiveLength = null);
      }
      this.saveRuleParam.limitExclusiveLength =
        this.saveRuleParam.limitExclusiveLength
          .replace(/[^0-9.]/g, "") //非0-9.
          .replace(/(^0+)([^.]+)/g, ($1, $2, $3) => $3); //开头的0
    },
    limitTimeBlur(e){
      if(this.saveRuleParam.limitExclusiveLength){
         if(parseInt(this.saveRuleParam.quoteWaitLength) >= parseInt(this.saveRuleParam.limitExclusiveLength)){
           this.saveRuleParam.quoteWaitLength = ''
           this.$message.error('等待报价时长需小于限时抢单时长')
         }
       }
    },
     // 等待报价时长规则
     waiteLimitChange(v) {
      this.saveRuleParam.quoteWaitLength = v;
      if (parseInt(v) < 0) {
        return (this.saveRuleParam.quoteWaitLength = null);
      }
      this.saveRuleParam.quoteWaitLength =
        this.saveRuleParam.quoteWaitLength
        .replace(/[^0-9]/g, '')
          .replace(/(^0+)([^.]+)/g, ($1, $2, $3) => $3); //开头的0
       if(this.saveRuleParam.limitExclusiveLength){
         if(parseInt(this.saveRuleParam.quoteWaitLength) >= parseInt(this.saveRuleParam.limitExclusiveLength)){
           this.saveRuleParam.quoteWaitLength = ''
           this.$message.error('等待报价时长需小于限时抢单时长')
         }
       }
    },
     // 限时抢单详情
     exclusiveList() {
      _api.exclusiveList().then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.timeLimitOrderList = res.data ? res.data : [];
          this.timeLimitOrderList = this.timeLimitOrderList.map((v, index) => {
            return {
              ...v,
              index: index + 1,
            };
          });
        }
      });
    },
    timeDeletClosed() {
      this.timeDeletShow = false;
      this.timeDeletCommand = "";
    },
    timeDeletSubmit() {
      let params = {
        command: this.timeDeletCommand,
        id: this.limitRow.id,
      };
      //请求
      _api.exclusiveRemoveById(params).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.$message.success(res.msg || "操作成功");
          this.timeDeletCommand = "";
          // 刷新限时抢单列表
          this.exclusiveList();
          this.timeDeletShow = false;
        }
      });
    },
      // 提交
      timeLimitCommandSubmit() {
      if (!this.timeLimitCommand) {
        this.$message.error("请输入动态口令");
        return;
      }
      let requestMethod=''
      let params = {
        command: this.timeLimitCommand,
        id: this.limitRow.id,
        isEnable: this.limitRow.type=='rab'? this.limitRow.isEnable:this.limitRow.isQuoteWait,
      };
      if(this.limitRow.type=='rab'){
        requestMethod= _api.exclusiveIsEnable //抢单时长
      }else{
        requestMethod=_api.updateWaitQuoteIsEnable  //报价等待
      }
      //请求
      requestMethod(params).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.$message.success(res.msg || "操作成功");
          this.timeLimitOpenProps = {};
          // 刷新限时抢单列表
          this.exclusiveList();
        }
      });
    },
      // 关闭
      timeLimitSwithClose() {
      this.timeLimitOpenProps = {};
      this.timeLimitCommand = "";
      // 刷新限时抢单列表
      this.exclusiveList();
    },
      // 添加/编辑规则确定
      submitTimeRule() {
      // 添加/编辑
      // 限时抢单时长规则（大于10的整数）
      const regex = /^[1-9]\d+$/;
      if (!regex.test(this.saveRuleParam.limitExclusiveLength)) {
        return this.$message.error("限时抢单时长为大于10的整数！");
      }
      // 请求保存接口
      const params = {
        ...this.saveRuleParam,
      };
      _api.exclusiveSave(params).then((res) => {
        if (res.code === 1) {
          if (this.ruleTitle === "添加规则") {
            this.timeLimitOrderList.push({
              ...this.saveRuleParam,
              index: this.timeLimitOrderList.length + 1,
            });
          }
          // 刷新限时抢单列表
          this.exclusiveList();
          this.ruleAddShow = false;
        }
      });
    },
      // 添加/编辑规则 关闭弹框
      ruleAddShowClose() {
      this.ruleAddShow = false;
      this.saveRuleParam = {};
    },
     // 开启限时抢单
     timeLimitOpen(e, row,type) {
      console.log(row, "777777",type);
      this.limitRow = row;
      this.limitRow.type = type;
      if (e) {
        this.timeLimitOpenProps = {
          ...this.timeLimitOpenProps,
          title: "开启提示",
          show: true,
        };
      } else {
        this.timeLimitOpenProps = {
          ...this.timeLimitOpenProps,
          title: "关闭提示",
          show: true,
        };
      }
    },
      // 限时抢单
      openTimeLimit() {
      this.timeLimitOrderShow = true;
      // 请求限时抢单详情
      this.exclusiveList();
    },
      // 删除当前行****
      deleteCurrentTime(row, event, column) {
      this.timeDeletShow = true;
      this.limitRow = row;
    },
     // 添加/编辑规则
     addRule(type, row) {
      this.ruleAddShow = true;
      if (type === "add") {
        //添加规则
        this.ruleTitle = "添加规则";
        //重置数据
        this.saveRuleParam = {};
      } else {
        //编辑规则
        this.ruleTitle = "编辑规则";
        //重置数据
        this.saveRuleParam = JSON.parse(JSON.stringify(row))
      }
      console.log(this.saveRuleParam, "this.saveRuleParamthis.saveRuleParam");
    },
    exportinDialogClosed() {
      this.importVisible = false
      this.importForm = {
        fileName: '',
        file: null
      };
      if (this.$refs["fileUpload"]) this.$refs["fileUpload"].value = "";
     },
    fileClear() {
      if (this.$refs["fileUpload"]) this.$refs["fileUpload"].value = "";
      this.importForm = {
        fileName: '',
        file: null
      };
    },
    // 选择文件
    fileChange(event) {
      console.log(event,'importFormimportFormimportFormimportForm');
      
      if (event.target.files) {
        const file = event.target.files[0]
        this.importForm.file = file;
        this.importForm.fileName = file.name;
      }
    },
    // 导入线下打款订单
    download() {
      const link = document.createElement("a");
      link.href = "/static/导入禁用商户数据模板.xlsx";
      link.setAttribute("download", "导入禁用商户数据模板.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    importSuccess() {
      const file = this.importForm.file
      if (!file) {
        return this.$message.error("请上传表格");
      }
      if (!this.command) {
        return this.$message.error("请输入动态口令");
      }
      const fd = new FormData();
      fd.append("file", file)
      fd.append("command", this.command)
      this.importLoading = true
      _api.importBatchDisableCompany(fd).then(res => {
        this.$message.success('导入成功！');
        this.importVisible = false
      }).finally(() => {
        this.fileClear()
        this.importLoading = false
      })
    },
    batchForbidden() {
      this.importVisible = true
    },
    //获取原始预估价检测金额
    getRecommendUseDetectionPrice() {
      _api.getRecommendUseDetectionPrice().then(res => {
          this.redyPrice=res.data||''
      })
    },
    //系统检测设置
    detectionSet() {
      this.getRecommendUseDetectionPrice()
      this.csetionShow = true
    },
    // 获取省市区
    getProvince() {
      _api.listAudit(0).then(r => {
        const index = this.formItemList.findIndex(v => v.key === 'provinceId');
        this.formItemList[index].option = r.data.map(v => {
          return {
            label: v.cityName,
            value: v.id
          }
        })
      })
    },
    // 获取市区
    getCity(v) {
      _api.listAudit(v).then(r => {
        const index = this.formItemList.findIndex(v => v.key === 'cityId');
        console.log(v);
        this.formItemList[index].option = r.data.map(v => {
          return {
            label: v.cityName,
            value: v.id
          }
        })
      })
    },
    // 搜索项变化
    handleChange(v1, v2) {
      if (v1.key === "provinceId") {
        const index = this.formItemList.findIndex(v => v.key === v1.childrenKey);
        if (v2) {
          this.formItemList[index].disabled = false;
          this.formItemList[index].placeholder = "请选择市"
          this.getCity(v2);
        } else {
          this.formItemList[index].placeholder = "请先选择省"
          this.formItemList[index].disabled = true;
        }
      }
    },
    addOneMenu() {
      window.sessionStorage.removeItem('storeShopEditStore');
      this.$router.push("/StoreShop/Addstore");
    },
    // 矩阵下拉
    getSelectMatrixList() {
      _api.getSelectMatrixList().then(r => {
        const data = r.data || [];
        this.matrixSelectList = data.map(v => {
          return {
            label: v.name,
            value: v.id
          }
        })
      })
    },
    handleConfirm(data,cd) {
      if (data) {
        // this.companyName = data.companyName;
        this.contact = data.contact;
        this.phone = data.phone;
        this.channel = data.channel;
        this.isSymbolUsed = data.isSymbolUsed;
        this.isOpenPc = data.isOpenPc;

      } else {
        // this.companyName = "";
        this.contact = "";
        this.phone = "";
        this.channel = "";
        this.isSymbolUsed = "";
        this.isOpenPc = "";
      }
      if (parseInt(this.startRecycles) < 0) {
        this.startRecycles = 0;
        return this.$message.error("开始累计订单数不能小于0");
      }
      if (parseInt(this.endRecycles) < 0) {
        return this.$message.error("结束累计订单数不能小于0");
      }
      if (parseInt(this.startRecycles) > parseInt(this.endRecycles)) {
        return this.$message.error('累计订单数量范围不正确');
      }
      const { matrixName, matrixId } = this.$route.query;
      console.log(data)
      const SeachParams = {
        merchantType: "01",
        companyName: this.companyName,
        companyId:this.companyId,
        contact: this.contact,
        phone: this.phone,
        // companyId: 0,
        pageNum: 1,
        pageSize: 10,
        channel: this.channel,
        isSymbolUsed: this.isSymbolUsed,
        isOpenPc: this.isOpenPc,
        orderType: this.orderType,
        startRecycles: parseInt(this.startRecycles),
        endRecycles: parseInt(this.endRecycles),
        matrixId: this.matrixId === matrixName ? matrixId : this.matrixId,
        cityId: data ? data.cityId : undefined,
        provinceId: data ? data.provinceId : undefined,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      };
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    // 设置收货地址
    setAddress() {
      _api.queryFormAddress().then((res) => {
        if (res.code == 1) {
          this.addressForm = {
            contactName: res.data.contactName,
            contactPhone: res.data.contactPhone,
            merchantAddress: res.data.merchantAddress,
            houseAddress: res.data.houseAddress,
            command: "",
          };
        }
      });
      this.setAddressShow = true;
    },
    // 设置收货地址弹框关闭
    setAddressClosed() {
      this.$refs["addressFormRules"].clearValidate();
    },
    // 确定设置地址
    addressSubmit() {
      this.$refs["addressFormRules"].validate((valid) => {
        if (valid) {
          this.butLoading = true;
          _api
            .savePlatformAddress(this.addressForm)
            .then((res) => {
              if (res.code == 1) {
                this.$message.success(res.msg || "操作成功");
                this.setAddressShow = false;
              }
              this.butLoading = false;
            })
            .catch((err) => {
              this.$message.error(err.msg || "操作失败");
              this.butLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 打开加价设置弹框
    openAddPrice() {
      this.$router.push("/StoreShop/priceMarkup");
      // 原弹窗操作
      // this.addPriceShow = true;
      // _api.getMakeupConfig().then(res => {
      //   if (res.code == 1) {
      //     console.log(res);
      //     this.isOpen = res.data.isOpen,
      //         this.regionList = res.data.regionList
      //   }
      // })
    },
    // 加价弹框确认
    submitAddPrice() {
      this.commandShow = true
    },
    // 加价配置编辑
    editConfig(row) {
      this.editPriceShow = true
      this.rowId = row.id
      this.editPriceForm = {
        lower: row.lower,
        upper: row.upper,
        markUpRate: row.markUpRate,
        markUpLimit: row.markUpLimit,
      }
    },
    commandClosed() {
      this.command = ""
    },
    editPriceClosed() {
      this.$refs["editPriceRulesForm"].resetFields();
      this.rowId = ""
      this.editPriceForm = {
        lower: "",
        upper: "",
        markUpRate: "",
        markUpLimit: "",
      }
    },
    // 编辑弹框确认
    submitEditPrice() {
      this.$refs["editPriceRulesForm"].validate((valid) => {
        if (valid) {
          this.regionList.forEach(item => {
            if (item.id == this.rowId) {
              item.markUpRate = this.editPriceForm.markUpRate
              item.markUpLimit = this.editPriceForm.markUpLimit
            }
          })
          this.editPriceShow = false
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //系统检测设置提交
    csetionSubmit() {
      if (!this.redyPrice) {
        this.$message.error('请输入设置金额')
        return
      }
      if (!this.command) {
        this.$message.error('请输入动态口令')
        return
      }
       //设置原始预估价检测金额
      _api.setRecommendUseDetectionPrice({
        command:this.command,
        price:this.redyPrice,
      }).then(res => {
          if(res.code==1){
            this.$message.success(res.msg || '操作成功')
            this.csetionShow = false
          }
      })
    },
    commandSubmit() {
      if (!this.command) {
        this.$message.error('请输入动态口令')
        return
      }
      this.commandBtnLoading = true
      let data = {
        command: this.command,
        isOpen: this.isOpen,
        regionList: this.regionList,
      }
      _api.saveMakeupConfig(data).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg || '操作成功')
          this.commandShow = false
          this.addPriceShow = false
        }
        this.commandBtnLoading = false
      }).catch(() => {
        this.commandBtnLoading = false
      })
    },
    limitInput(value, name) {
      let str = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (str[0] == ".") {
        this.editPriceForm[name] = ""
        return;
      }
      // 过滤掉除数字和小数点外的字符
      value = str.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有两位小数
      let valJoin = value.join("");
      this.editPriceForm[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || "";
      if (this.editPriceForm[name] > 100) {
        this.editPriceForm[name] = '100'
      }
    },
    // 失焦
    inputBlur(name) {
      console.log(this.editPriceForm[name]);
      if (this.editPriceForm[name].toString().indexOf('.') == (this.editPriceForm[name].length - 1)) {
        this.editPriceForm[name] = this.editPriceForm[name].replace('.', '')
      }
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.demo-image__preview{
  margin-left: 80px;
  margin-top: -25px
}
  .limit_time {
    /deep/.el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 170px !important;
    }
  }
  .delet-btn {
    font-size: 14px;
    font-weight: 500;
    color: #ff0000;
    text-align: center;
    cursor: pointer;
    margin-left: 10px;
  }
.GlobalFormDemo {
  .address_dialog {
    /deep/ .el-form-item {
      margin-bottom: 10px;
    }

    /deep/ .el-form-item__error {
      padding: 0px;
    }
  }

  .futureprices {
    display: flex;
    align-items: center;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .mt-20 {
    margin-top: 20px;
  }

  /deep/ .el-input-group__append {
    border: 1px solid #0981ff;
    background: #0981ff;
    color: #ffffff;
  }

  /deep/ .demo-ruleForm .el-form-item__content .el-input-group {
    vertical-align: middle;
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
}
.cance_tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;
}
.import-business-dialog{
  .fileName {
    /deep/.el-input {
      width: 260px;
    }
  
    /deep/.el-button {
      // border-radius: 10px;
      margin-right: 0px;
    }
  }
}

//去除数字框上下箭头
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type='number'] {
  -moz-appearance: textfield;
}
.addparto {
    font-size: 12px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ff8080;
  }
  .service_charge {
    /deep/ .el-switch__label * {
      line-height: 1;
      font-size: 12px;
      display: inline-block;
    }

    /deep/ .el-switch__label {
      position: absolute;
      display: none;
      color: #fff !important;
      font-size: 12px !important;
    }

    /*打开时文字位置设置*/
    /deep/ .el-switch__label--right {
      z-index: 1;
      right: 4px;
    }

    /*关闭时文字位置设置*/
    /deep/ .el-switch__label--left {
      z-index: 1;
      left: 4px;
    }

    /*显示文字*/
    /deep/ .el-switch__label.is-active {
      display: block;
    }

    /*开关宽度*/
    /deep/ .el-switch .el-switch__core,
    /deep/ .el-switch .el-switch__label {
      width: 50px !important;
    }
  }
</style>
